const navbar = document.querySelector('nav'),
      navbarNav = navbar.querySelector('ul').cloneNode(true),
      offcanvas = document.createElement('div'),
      navbarLogo = document.querySelector('header a.logo img').cloneNode(true),
      toggleButton = document.createElement('button'),
      logo = document.createElement('div');

/**
 * Prepare logo
 */
logo.classList.add('logo');
logo.append(navbarLogo);

/**
 * Prepare nav
 */
navbarNav.classList = '';

navbarNav.querySelectorAll('li').forEach(li => {
    const isParent = li.querySelector('ul.wp-block-navigation-submenu');
    const isCurrent = li.classList.contains('current-menu-item') || li.classList.contains('current-menu-ancestor');

    li.classList = 'menu-item';

    if (isParent) {
        li.classList.add('menu-item-has-children');
    }

    if (isCurrent) {
        li.classList.add('current-menu-item');
    }
});

navbarNav.querySelectorAll('ul.second').forEach(submenu => {
    const li = submenu.closest('li'),
          a = li.querySelector(':scope > a');

    submenu.classList = 'sub-menu';

    a.innerHTML += '<span class="caret"></span>'
    a.onclick = e => {
        e.preventDefault()
        e.stopPropagation();

        li.classList.toggle('open')
    }
})

navbarNav.querySelectorAll('li.current-menu-ancestor').forEach(li => {
    li.classList.add('open')
})

// insert caret in each toggle link
// $nav.find('ul.submenu').each((i, el) => {
//     $(el).prev('a').append('<span class="caret"></span>');
// });
// $offcanvas.on('click', 'li.menu-item-has-children > a.nav-link', e => {
//     e.stopPropagation();
//     e.preventDefault();
//     let $link = $(e.target).closest('a.nav-link'),
//         $menu = $link.next('.submenu'),
//         $li = $link.parent();
//     $li.toggleClass('open');
// });

offcanvas.id = 'offcanvas-menu';
offcanvas.append(logo);
offcanvas.append(navbarNav);

/** Prepare navbar itself */
// navbar.after(offcanvas);
document.body.append(offcanvas);

document.querySelector('header').append(toggleButton);
toggleButton.classList.add('offcanvas-toggle');
toggleButton.innerHTML = '<span></span><span></span><span></span>';

toggleButton
    .addEventListener('click', (e) => {
        e.stopPropagation();

        document.body.classList.toggle('offcanvas-open')
    });

document.body.addEventListener('click', e => {
    if (e.target.closest('#offcanvas-menu')) return;

    if (document.body.classList.contains('offcanvas-open')) {
        document.body.classList.remove('offcanvas-open');
    }
})

// $(document).click(e => {

//     if(!$(e.target).hasClass('navbar-toggler') && !$(e.target).parent().hasClass('navbar-toggler') && $(e.target).closest('#offcanvas-menu').length == 0) {
//         $("body").removeClass('offcanvas-open');
//     }

// });