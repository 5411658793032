import Splide from '@splidejs/splide';

const thumbCarousel = new Splide('#thumbnail-carousel', {
    gap: 2,
    rewind: true,
    pagination: false,
    direction: 'ttb',
    height: '294px',
    arrows: false,
    isNavigation: true,
    perPage: 3,
    fixedHeight: 96.5,
    breakpoints: {
        959: {
            direction: 'ltr',
            height: 'auto',
            fixedHeight: 0,
            type: 'loop',
        },
        767: {
            perPage: 2
        },
        500: {
            perPage: 1
        }
    }
});

const mainCarousel = new Splide('#main-carousel', {
    type: 'loop',
    rewind: true,
    pagination: false,
    arrows: false,
    heightRatio: 0.45581,
    direction: 'ttb',
    height: '294px',
    autoplay: true,
    interval: 4000,
    breakpoints: {
        959: {
            direction: 'ltr',
            heightRatio: 0.5,
            height: null
        },
        767: {
            heightRatio: 0.6,
        },
        500: {
            heightRatio: 0.75
        }
    }
});

mainCarousel.sync(thumbCarousel);
mainCarousel.mount();
thumbCarousel.mount();

// const slider = new Splide('.splide', {
//     direction: 'ttb',
//     height: '294px',
//     arrows: false,
//     pagination: true,
//     // width: '645px',
//     paginationDirection: 'ttb',
// });

// slider.on('pagination:mounted', (data) => {
//     data.items.forEach((item, index) => {
//         const slide = slider.Components.Elements.slides[index];

//         item.button.innerHTML = `
//             <span class="title">${ slide.dataset.teaserTitle }</span>
//             <span class="sub">${ slide.dataset.teaserDescription }</span>
//         `;
//     });
// });

// slider.mount();